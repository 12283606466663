import React from 'react';

import { IconBasePropsWithColor } from './IconBase';

import { colors } from 'styles/cp';

const Download: React.FC<IconBasePropsWithColor> = ({
  width = 24,
  height = 24,
  color = colors.white,
  className = '',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      className={className}
      aria-label="Download"
    >
      <path
        fill={color}
        fillRule="evenodd"
        aria-label="Download path"
        d="M20 11c.553 0 1 .448 1 1v6c0 1.654-1.346 3-3 3H6c-1.654 0-3-1.346-3-3v-6c0-.552.447-1 1-1 .553 0 1 .448 1 1v6c0 .55.448 1 1 1h12c.552 0 1-.45 1-1v-6c0-.552.447-1 1-1zm-8-8c.553 0 1 .448 1 1v9.586l2.293-2.293c.391-.391 1.023-.391 1.414 0 .391.39.391 1.023 0 1.414l-3.999 3.999c-.093.093-.203.166-.326.217-.122.051-.252.077-.382.077s-.26-.026-.382-.077c-.123-.051-.233-.124-.326-.217l-3.999-3.999c-.391-.391-.391-1.023 0-1.414s1.023-.391 1.414 0L11 13.586V4c0-.552.447-1 1-1z"
      />
    </svg>
  );
};

export default Download;
